import axios from 'axios';
import { Session } from '@/models/Session';
import { Attachment } from '@/models/Attachment';
import { Message } from '@/models/Message';
import { Transaction } from '@/models/Transaction';

class Api {

  public baseUrl = this.solveBaseUrl();

  //todo, hae konffista tai erota apit omikseen 
  private solveBaseUrl() {
    const url = window.location.href; 
    if(url.indexOf("asiointi-testi.lahti.fi")>0)
      return "https://asiointi-testi-api.lahti.fi/";
    else if(url.indexOf("asiointi.lahti.fi")>0)
      return "https://asiointi-api.lahti.fi/";
    else if(url.indexOf("d10-sa-dev")>0)
      return "https://d10-sa-dev-api.azurewebsites.net/";
    else
      return process.env.VUE_APP_APIURL
  }

  private axiosInstance() {
    return axios.create({
			baseURL: this.baseUrl,
      headers: {
        //Authorization: 'Bearer ' + token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      withCredentials: true  
    });
  }

  GetServices() {
    return this.axiosInstance().get('api/user/EServices/'); 
  }

  GetEService(eServiceId: string) {
    return this.axiosInstance().get(`api/user/EServices/${eServiceId}`);
  }

  GetFormSchema(eServiceId: string, formId: string) {
    return new Promise((resolve, reject) => {    
      const url = `api/user/Forms/Schema/${eServiceId}/${formId}`;
      this.axiosInstance().get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          //401=unauthorized=session is old, removed or invalid
          //if(e.response !== null && e.response.status === 401)
          //  startLogout(true); //todo
  
          reject(e);
        });
    });
  }

  // GetDraftFormSchema(draftId: string) {
  //   return new Promise((resolve, reject) => {    
  //     const url = `api/user/DraftForm/Schema/${draftId}`;
  //     this.axiosInstance().get(url)
  //       .then(response => {
  //         resolve(response.data);
  //       })
  //       .catch(e => {
  //         //401=unauthorized=session is old, removed or invalid
  //         //if(e.response !== null && e.response.status === 401)
  //         //  startLogout(true); //todo
  
  //         reject(e);
  //       });
  //   });
  // }
  
  CreateTransaction(eServiceId: string, formId: string, parentTransactionId: string) {
    return this.axiosInstance().get(`api/user/Transactions/${eServiceId}/${formId}/${parentTransactionId}`);
  }  

  CreateTransactionByShortCode(eServiceShortCode: string, formShortCode: string) {
    return this.axiosInstance().get(`api/user/Transactions/${eServiceShortCode}/${formShortCode}`);
  }  

  GetTransaction(guid: string) {
    return this.axiosInstance().get(`api/user/Transactions/${guid}`);
  }  

  GetTransactions() {
    return this.axiosInstance().get(`api/user/Transactions`);
  }

  GetTransactionsByCase(caseId: number) {
    return this.axiosInstance().get(`api/user/Transactions/bycase/${caseId}`);
  }

  SaveTransaction(transaction: Transaction) {
    return this.axiosInstance().post(`api/user/Transactions`, transaction);
  }

  GetAttachments(transactionId: string, componentId: string) {
    return this.axiosInstance().get(`api/user/Transactions/GetAttachments/${transactionId}/${componentId}`);
  }

  AddAttachment(transactionId: string, componentId: string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.axiosInstance().post(`api/user/Transactions/AddAttachment/${transactionId}/${componentId}`, formData);
  }

  RemoveAttachment(attachment: Attachment) {
    return this.axiosInstance().delete(`api/user/Transactions/RemoveAttachment/${attachment.TransactionId}/${attachment.ComponentId}/${attachment.Guid}`);
  }


  


  // PostTransactionAttachments(transactionId: string, attachments: Array<File>) {
  //   const formData = new FormData();
  //   attachments.forEach(file => {
  //     formData.append("file", file);
  //   });

  //   return this.axiosInstance().post(`api/user/Transactions/attachments/${transactionId}`, formData);
  // }

  // PostDraftForm(draftForm: Record<string, any>) {
  //   return new Promise((resolve, reject) => {
  //     //let loginData = getUser();
  //     const url =  'api/user/DraftForm';
  //     this.axiosInstance().post(url,draftForm)
  //       .then(response => {
  //         resolve(response.data);
  //       })
  //       .catch(e => {
  //         //console.log(e);
          
  //         // //401=unauthorized=session is old, removed or invalid
  //         // if(e.response !== null && e.response.status === 401)
  //         //   startLogout(true); //todo
        
  //         reject(e);
  //       });
  //   });
  // }

  // PostDraftAttachments(draftId: string, attachments: Array<File>) {
  //   const formData = new FormData();
  //   attachments.forEach(file => {
  //     formData.append("file", file);
  //   });

  //   return this.axiosInstance().post(`api/user/DraftForm/attachments/${draftId}`, formData);
  // }

  // PutDraftForm(draftForm: Record<string, any>) {
  //   return new Promise((resolve, reject) => {
  //     const url =  'api/user/DraftForm';
  //     this.axiosInstance().put(url,draftForm)
  //       .then(response => {
  //         resolve(response.data);
  //       })
  //       .catch(e => {
  //         //console.log(e);
          
  //         // //401=unauthorized=session is old, removed or invalid
  //         // if(e.response !== null && e.response.status === 401)
  //         //   startLogout(true); //todo
        
  //         reject(e);
  //       });
  //   });
  // }

  GetSession() {
    return this.axiosInstance().get('api/user/session');
  }

  GetAllSessions() {
    return this.axiosInstance().get('api/user/session/all');
  }

  SaveSession(session: Session) {
    return this.axiosInstance().post('api/user/session/', session);
  }

  ChangeRole(sessionGuid: string) {
    return this.axiosInstance().get('api/user/session/changerole/'+sessionGuid);
  }

  AddSession(session: Session) {
    return this.axiosInstance().put('api/user/session/', session);
  }

  GetOrgUsers() {
    return this.axiosInstance().get('api/user/session/orgusers');
  }

  UpdateOrgUserRole(guid: string, role: number) {
    return this.axiosInstance().get(`api/user/session/updaterole/${guid}/${role}`);
  }

  SuomiFiLogin(returnRoute: any) {
    if(!returnRoute || returnRoute=="undefined") returnRoute = "";
    return this.axiosInstance().get(`api/user/suomifi/login/${returnRoute}`);
  }

  SuomiFiLogout() {
    return this.axiosInstance().get('api/user/suomifi/logoutRedirect');
  }

  GetDrafts() {
    return this.axiosInstance().get('api/user/DraftForm');
  }

  DeleteDraft(id: string) {
    return this.axiosInstance().delete(`api/user/Transactions/${id}`);
  }

  GetCases() {
    return this.axiosInstance().get('api/user/Cases');
  }

  GetCase(caseId: number) {
    const url = `api/user/Cases/${caseId}`;
    return this.axiosInstance().get(url);
  }

  GetDocDLUrl(guid: string) {
    return this.baseUrl + 'api/user/Cases/DownloadDocument/' + guid;
  }

  // GetAttDLUrl(docguid: string, attguid: string) {
  //   return this.baseUrl + `api/user/Cases/DownloadAttachment/${docguid}/${attguid}`;
  // }

  GetCompanyData(bic: string) {
    return new Promise((resolve, reject) => {
      axios.get(`https://avoindata.prh.fi/bis/v1/${bic}`)
        .then(response => {
          if(response.data.results.length > 0){
            resolve(response.data.results[0]);
          } else{
            resolve({
              name: ''
            });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  GetMessages() {
    return this.axiosInstance().get('api/user/messages');
  }

  MarkAllAsRead() {
    return this.axiosInstance().get('api/user/messages/markallasread')
  }

  MarkMessageAsOpened(message: Message) {
    return this.axiosInstance().post('api/user/messages/markasopened', message)
  }

  ContentData() {
    return this.axiosInstance().get('api/user/settings/contentdata')
  }

  FaviconUrl() {
    return this.baseUrl+'api/user/settings/file/favicon';
  }

  LogoUrl() {
    return this.baseUrl+'api/user/settings/file/logo';
  }

  MessageHubUrl() {
    return this.baseUrl+'messagehub';
  }


}

const api = new Api();
export default api;

