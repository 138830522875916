





































































































































































































import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { Session } from "@/models/Session";
import moment from "moment";

@Component
export default class UserInfo extends Vue {
  private session!: Session;
  private allSessions = [];
  private disabled = true;
  private bic = "";
  private organization = "";
  private bicFound = false;
  private valid = false;
  private selectedSession = "";
  private selectedSessionGuid = "";

  @Prop() readonly mode!: string;

  created() {
    this.session = this.$store.state.session;
    this.selectedSessionGuid = this.session.GUID ?? "";
    this.getSessions();
  }

  getSessions() {
    this.$api
      .GetAllSessions()
      .then((response) => {
        this.allSessions = response.data;
      })
      .catch((e) => {
        console.log("error gettin all sessions");
      });
  }

  public save(): void {
    this.$api
      .SaveSession(this.session)
      .then((response) => {
        console.log("saved");
      })
      .catch((e) => {
        console.log("error updating session data");
      });
  }

  public changeRole(): void {
    this.session.GUID = this.selectedSessionGuid;
    this.$api
      .ChangeRole(this.selectedSessionGuid)
      .then((response) => {
        //pitää päivittää koko sivu, että keksit päivittyy
        window.location.href = window.location.href.toString();
      })
      .catch((e) => {
        console.log("error updating session data");
      });
  }

  public addorg(): void {
    const newSession = new Session();
    newSession.BIC = this.bic;
    newSession.OrganizationName = this.organization;

    this.$api
      .AddSession(newSession)
      .then((response) => {
        //console.log("session added");
        this.getSessions();
      })
      .catch((e) => {
        console.log("error updating session data");
      });
  }

  isValid() {
    return this.valid;
  }

  get state() {
    if (!this.$helpers.isValidEmail(this.session.Mail || "")) return false;
    return null;
  }

  @Watch("bic", { immediate: false, deep: true })
  onBicChange(newVal: any) {
    this.$api
      .GetCompanyData(newVal)
      .then((response: any) => {
        if (response && response.name) {
          //console.log("Company found: " + response.name);
          this.organization = response.name;
          this.bicFound = true;
        } else {
          this.bicFound = false;
        }
      })
      .catch((e) => {
        console.log("Company not found");
        this.bicFound = false;
      });
  }
}
