<template>
  <div>
    <h3>{{ text() }}</h3>
  </div>
</template>

<script>
import extendComponent from '@/mixins/extendComponent'
export default {
  mixins: [extendComponent]
}
</script>
