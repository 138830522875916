<template>
  <b-form-input
    :id="'component_' + component.id"
    type="number"
    v-model="component.value"
    :step="component.step"
    :min="component.min"
    :state="getState()"
    @change="onChange()"
    @blur="onBlur()"
    lazy
  />
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
export default {
  mixins: [extendComponent],

  // created () {
  // },

  methods: {
    validate() {
      //check required
      if (this.component.required && !this.component.value) {
        this.onValidation("Tämä kenttä on pakollinen.");
        this.isValid = false;
        return;
      }

      // //check random todo
      // if (this.component.value < 5) {
      //   this.onValidation("Arvon pitää olla vähintään 5");
      //   this.isValid = false;
      //   return
      // }

      this.onValidation("");
      this.isValid = true;
    },
  },
};
</script>
