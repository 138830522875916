<template>
  <b-form-checkbox-group
    :id="'component_' + component.id"
    v-model="selections"
    :state="getState()"
    @change="onChange()"
    @blur="onBlur()"
    lazy
  >
    <div
      v-for="option of component.options"
      :key="option.id"
      class="option-height"
    >
      <b-form-checkbox :value="option">{{
        optionText(option.text)
      }}</b-form-checkbox>
    </div>
    <b-form-invalid-feedback :state="getState()">{{invalidFeedback}}</b-form-invalid-feedback>
  </b-form-checkbox-group>
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
export default {
  mixins: [extendComponent],
    data() {
    return {
      selections: null,
      validFeedback: "",
      invalidFeedback: "",
    };
  },
  methods: {
    validate() {
      //check required
      //console.log(this.component.selections);
      if (this.component.required && (!this.component.selections || this.component.selections.length==0) ) {
        this.invalidFeedback = "Tämä kenttä on pakollinen.";
        this.isValid = false;
        return;
      }
      this.invalidFeedback = "";
      this.isValid = true;
    },
  },

  watch: {
    selections(val) {
      this.component.selections = val;
    },
  },

  created() {
    this.selections = this.component.selections;
  },

};
</script>
