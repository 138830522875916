


























import { Component, Vue } from "vue-property-decorator";

@Component
export default class Login extends Vue {

    public login(): void {
    this.$api.SuomiFiLogin(this.$route.query.r).then((response) => {
      window.location.href = response.data;
    });
  }
}
