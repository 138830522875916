<template>
  <b-form-radio
    :id="'component_' + component.id"
    v-model="component.value"
    :state="getState()"
    @change="onChange()"
    @blur="onBlur()"
    lazy
    >{{ label() }}</b-form-radio
  >
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
export default {
  mixins: [extendComponent],
  methods: {
    validate() {
      //check required
      if (this.component.required && !this.component.value) {
        this.onValidation("Tämä kenttä on pakollinen.");
        this.isValid = false;
        return;
      }
      this.onValidation("");
      this.isValid = true;
    },
  },
};
</script>
