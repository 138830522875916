






import { Component, Vue } from "vue-property-decorator";
import OrgInfo from "@/components/OrgInfo.vue";

@Component({
  components: {
    OrgInfo,
  },
})
export default class ViewOrgInfo extends Vue {}
