














































































































































































import { Component, Vue } from "vue-property-decorator";
import Messages from "@/components/Messages.vue";
import Settings from "@/components/Settings.vue";
import { Message } from "@/models/Message";

@Component({
  components: {
     Messages, Settings
  },
})export default class Navbar extends Vue {
  private settingsVisible = false;
  private messageVisible = false;

  public login(): void {
    this.$api.SuomiFiLogin(this.$route.query.r).then((response) => {
      window.location.href = response.data;
    });
  }

  public logout(): void {
    this.$api.SuomiFiLogout().then((response) => {
      window.location.href = response.data;
    });
  }

  public name(): string {
    return (
      this.$store.state.session.GivenName +
      " " +
      this.$store.state.session.LastName
    );
  }

  public org(): string {
    return this.$store.state.session.OrganizationName
      ? this.$store.state.session.OrganizationName + " (" + this.$store.state.session.BIC + ")"
      : "";
  }

  get unreadMessagesCount() {
    if (this.$store.state.messages)
      return this.$store.state.messages.filter((x: Message) => !x.ReadDate)
        .length;
    return 0;
  }

  get Session() {
    return this.$store.state.session;
  }

  public hideSettings(): void {
    this.settingsVisible = false;
  }

  public hideMessages(): void {
    this.messageVisible = false;
  }

  public toggleMessages(): void {
    this.messageVisible = !this.messageVisible;
    if (this.messageVisible && this.unreadMessagesCount > 0) {
      this.markAllAsRead();
    }
  }



  public markAllAsRead() {
    this.$api
      .MarkAllAsRead()
      .then((response) => {
        this.$store.commit("updateMessages", response.data);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }
}
