import { LanguageItem } from '@/models/LanguageItem'
import moment from "moment";

class Helpers {
  public ParseName(item: Array<LanguageItem>, locale: string) {
    if(item && item.length > 0) {
      const langitem = item.filter(x => x.language === locale);
      if(langitem.length>0) return langitem[0].text;
      else {
        console.log('langitem not found');
        return item[0].text;
      }
    }
    return '';
  }
  public formatDate(val: any) {
    return moment(val).local().locale('fi').format("D.M.YYYY");
  }
  public formatDateTime(val: any) {
    return moment(val).local().locale('fi').format("D.M.YYYY k:mm");
  }
  public isValidEmail(email: string) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
  public fromNow(val: Date) {
    return moment.utc(val).local().locale('fi').fromNow(); //todo locale
  }

}

const helpers = new Helpers();
export default helpers;