<template>
  <b-form-input
    :id="'component_' + component.id"
    type="number"
    v-model="component.value"
    :step="component.step"
    :min="component.min"
    :state="getState()"
    @change="onChange()"
    @blur="onBlur()"
    lazy
  >
    <template slot="append">&euro;</template>
  </b-form-input>
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
export default {
  mixins: [extendComponent],
  methods: {
    validate() {
      //check required
      if (this.component.required && !this.component.value) {
        this.onValidation("Tämä kenttä on pakollinen.");
        this.isValid = false;
        return;
      }
      this.onValidation("");
      this.isValid = true;
    },
  },
};
</script>
