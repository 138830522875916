import Vue from 'vue'
import Vuex from 'vuex'
import { MenuItem } from "@/models/MenuItem";
import { Case } from "@/models/Case";
import { Session } from "@/models/Session";
import { Message } from "@/models/Message";
import api from "@/api/Api";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    eServices: null,
    session: null,
    menuItems: Array<MenuItem>(),
    selectedMenuItem: null,
    cases: null,
    messages: Array<Message>(),
  },
  mutations: {
    updateMessages (state, val) {
      state.messages = val;
    },
    updateMenuItems (state, val) {
      state.menuItems = val;
    }
  },
  actions: {
    checkMessages (context) {
      //console.log('store.action: checkMessages');
      api
        .GetMessages()
        .then((response) => {
          context.commit('updateMessages', response.data);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });      
    }
  },
  modules: {
  }
})
