<template>
  <b-form-select
    :id="'component_' + component.id"
    class="w-100"
    clearable
    v-model="selection"
    :state="getState()"
    @change="onChange()"
    @blur.native = "onBlur()"
    lazy
  >
    <b-form-select-option :value="null">{{placeholder()}}</b-form-select-option>
    <b-form-select-option
      v-for="option in component.options"
      :key="option.id"
      :label="optionText(option.text)"
      :value="option"
    />
  </b-form-select>
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
export default {
  mixins: [extendComponent],
  methods: {
    validate() {
      //check required
      if (this.component.required && !this.component.selection) {
        this.onValidation("Tämä kenttä on pakollinen.");
        this.isValid = false;
        return;
      }
      this.onValidation("");
      this.isValid = true;
    },
  },
 
  watch: {
    selection(val) {
      this.component.selection = val;
      this.component.value = (val) ? this.optionText(val.text) : "";
    },
  },

  created() {
    if(this.component.selection)
      this.selection = this.component.selection;
  },

  data() {
    return {
      selection: null,
    };
  },
};
</script>
