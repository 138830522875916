<template>
  <div class="pt-3">
    <b-card bg-variant="light">
      <h4
        class="header"
        v-for="title of fieldBlock.title"
        :key="title.language + '_title'"
        v-if="selectedLanguage === title.language && title.text !== ''"
      >
        {{ title.text }}
      </h4>
      <p
        v-for="info of fieldBlock.info"
        :key="info.language + '_info'"
        v-if="selectedLanguage === info.language && info.text !== ''"
      >
        {{ info.text }}
      </p>

      <div v-for="columnData of fieldBlock.columnData" :key="columnData.id">
        <div v-for="component of columnData.components" :key="component.id">
          <b-form-row>
              <form-components
                :selected-language="selectedLanguage"
                :component="component"
                :blockDirty="blockDirty"
              />
          </b-form-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import FormComponents from "@/components/form/FormComponents";

export default {
  props: {
    fieldBlock: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedLanguage: {
      type: String,
      default: "",
    },
    sectionDirty: {
      type: Boolean,
      default: false,
    },
  },
  // methods: {
  //   emitAttachments(val) {
  //     this.$emit("send:attachments", val);
  //   },
  // },
  computed: {
    blockDirty() {
      return this.sectionDirty;
    },
  },
  components: {
    FormComponents,
  },
};
</script>
