const extendComponent = {
  props: ['selectedLanguage', 'component', 'onValidation', 'blockDirty'],
  data () {
    return {
      isDirty: false,
      isValid: true,
    };
  },

  methods: {
    placeholder () {
      if(this.component.placeholder){
        return this.component.placeholder.find(x => { return x.language === this.selectedLanguage; }) !== undefined ? this.component.placeholder.find(x => { return x.language === this.selectedLanguage; }).text : '';
      } else{
        return '';
      }
    },
    label () {
      if(this.component.label){
        return this.component.label.find(x => { return x.language === this.selectedLanguage; }) !== undefined ? this.component.label.find(x => { return x.language === this.selectedLanguage; }).text : '';
      } else{
        return 'n/a';
      }
    }, 
    info () {
      if(this.component.info){
        return this.component.info.find(x => { return x.language === this.selectedLanguage; }) !== undefined ? this.component.info.find(x => { return x.language === this.selectedLanguage; }).text : '';
      } else{
        return '';
      }
    }, 
    text () {
      if(this.component.text){
        return this.component.text.find(x => { return x.language === this.selectedLanguage; }) !== undefined ? this.component.text.find(x => { return x.language === this.selectedLanguage; }).text : '';
      } else{
        return '';
      }
    }, 
    optionText (text) {
      if(text){
        return text.find(x => x.language === this.selectedLanguage) !== undefined ? text.find(x => x.language === this.selectedLanguage).text : '';
      } else {
        return '';
      }
    },
    onChange() {
      this.isDirty = true;
    },
    onBlur() {
      this.isDirty = true;
    },
    getState() {
      this.validate();

      //merkataan jsoniin
      this.component.isValid = this.isValid;

      //palautetaan state vain jos koskettu ja virheellinen (merkkaa punaiseksi)
      if(this.dirty && !this.isValid)
        return false; 
      
    },
  },
  computed: {
    valid() {
      return this.isValid;
    },
    dirty() {
      return this.component.isDirty || this.isDirty || this.blockDirty;
    },
  }
};

export default extendComponent;