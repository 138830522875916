interface Dictionary<TValue> {
  [id: string]: TValue;
}

export const messages = {
  fi: {
    messages: {
      sendFormOk: "Lomake lähetetty",
      sendFormDraftOk: "Lomakkeen luonnos tallennettu",
      sendFormError: "Lomakkeen lähettäminen epäonnistui",
      sendFormDraftError: "Lomakkeen luonnoksen tallentaminen epäonnistui",
      getFormError: "Lomakkeen hakeminen epäonnistui",
    },
    logout: {
      sessionNotValidAnymore:
        "Istuntosi on vanhentunut. Voit kirjautua uudelleen käyttääksesi tunnistamista vaativia palveluja",
      userLoggedOut:
        "Olet kirjautunut ulos palvelusta. Voit kirjautua uudelleen käyttääksesi tunnistamista vaativia palveluja",
    },
    validation: {
      bic: {
        empty: "Y-tunnus puuttuu",
        hyphen: "Väliviiva puuttuu",
        length: "Liian lyhyt tunnus",
        char: "Löytyi kuulumaton merkki",
        checkNumber: "Tarkistusnumero ei täsmää",
      },
      ssn: {
        empty: "Henkilötunnus puuttuu",
        punctuationMark: "Väärä välimerkki",
        dateOfBirth: "Väärä päivämäärä",
        individialNumber: "Väärä yksilönumero",
        checkNumber: "Tarkistusnumero ei täsmää",
      },
      email: {
        empty: "Sähköpostiosoite puuttuu",
        valid: "Tarkista sähköpostiosoite",
      },
      attachments: {
        required: "Valitse vähintään yksi liite",
      },
    },
    header: {
      categories: {
        citizen: "Henkilö",
        enterprise: "Yritys",
        organization: "Organisaatio",
      },
      nameAndLogo: {
        eservice: "Asiointipalvelut",
      },
      navbar: {
        frontpage: "Etusivu",
        eservices: "PALVELUT",
        transactions: "OMA ASIOINTI",
        login: "KIRJAUDU SISÄÄN",
        logout: "KIRJAUDU ULOS",
      },
    },
    category: {
      category: "Palvelu",
      categories: "Palvelut",
      categoryItems: {
        citizen: "Kansalainen",
        enterprise: "Yritys",
        organization: "Organisaatio",
      },
    },
    components: {
      upload: {
        drop: "Tiputa tiedosto tähän tai",
        click: "klikkaa tästä",
        error: {
          notSupported: "Sallitut tiedostotyypit ovat ",
          tooBig: "Tiedosto on liian iso. Maksimikoko on ",
          exceedsLimit: "Liitteiden maksimimäärä on ",
        },
      },
    },
    form: {
      notAvailabe: "Ei saatavilla",
      button: {
        cancel: "PERUUTA",
        saveDraft: "TALLENNA LUONNOS",
        sendForm: "LÄHETÄ",
      },
      confirm:
        "Vakuutan tiedot oikeiksi ja annan suostumukseni tietojen tallentamiseen",
      requiresAuthentication: "Vaatii tunnistautumisen - kirjaudu sisään",
    },
    transaction: {
      transactions: "Omat asioinnit",
      draftForms: "Keskeneräiset lomakkeet",
      submittedForms: "Lähetetyt lomakkeet",
      submitTime: "Lähetysaika",
      form: "Lomake",
      status: "Tila",
      statuses: {
        status1: "Odottaa toimitusta taustajärjestelmään",
        status2: "Toimitettu taustajärjestelmään",
      },
      extStatus: {
        extStatus0: "Avattu",
        extStatus1: "Vireillä",
        extStatusna: "n/a",
      },
      eServiceCaseNumber: "Asianumero",
    },
    drafts: {
      submitTime: "Lähetysaika",
      category: "Kategoria",
      eService: "Palvelu",
      form: "Lomake",
      operations: "Toiminnot",
      edit: "Muokkaa",
    },
    login: {
      caption: "Demo sisäänkirjautuminen",
      pleaseInput: "Syötä teksti",
      sSN: "Hetu",
      firstname: "Etunimi",
      lastname: "Sukunimi",
      loginButton: "Kirjaudu sisään",
    },
    formSentSuccessfully: {
      breadcrumb: "Lomake lähetetty onnistuneesti",
      message: "Olemme vastaanottaneet lähettämäsi lomakkeen.",
      message2: "Asianumerosi on ",
    },
    userContent: {
      headerLogotitle: "Leimun kunta",
      frontpageTitle: "Leimun asiointi",
      frontpageSubtitle: "Tervetuloa Leimun kunnan sähköisiin asiointipalveluihin.",
      frontpageParagraph: "Täältä löydät Leimun kunnan sähköiset palvelut. Palvelujen käyttö edellyttää vahvaa tunnistautumista. Tutustuthan asiointipalvelun tietosuojaselosteeseen.",
    } as Dictionary<string>,
  },
  en: {
    messages: {
      sendFormOk: "Form sent",
      sendFormDraftOk: "Form draft saved",
      sendFromError: "Unable to send form",
      //sendFromError: 'Unable to save draft form',
      getFormError: "Unable to get form",
    },
    logout: {
      sessionNotValidAnymore:
        "Your session has been expired. You can log-in again to use the services that require identification.",
      userLoggedOut:
        "You are logged out of the service. You can log-in again to use the services that require identification.",
    },
    validation: {
      bic: {
        hyphen: "Missing hyphen",
        length: "Too short",
        char: "Inappropriate character found",
        checkNumber: "Invalid checknumber",
      },
      ssn: {
        punctuationMark: "Wrong punctuation mark",
        dateOfBirth: "Wrong date",
        individialNumber: "Wrong individial number",
        checkNumber: "Invalid checknumber",
      },
      email: {
        valid: "Invalid email",
      },
      attachments: {
        required: "Please select at least one attachment",
      },
    },
    header: {
      categories: {
        citizen: "Citizen",
        enterprise: "Enterprise",
        organization: "Organization",
      },
      nameAndLogo: {
        eservice: "EServices",
      },
      navbar: {
        frontpage: "Front page",
        eservices: "SERVICES",
        transactions: "MY TRANSACTIONS",
        drafts: "DRAFTS",
        login: "LOGIN",
        logout: "LOGOUT",
      },
    },
    category: {
      category: "Category",
      categories: "Categories",
      categoryItems: {
        citizen: "Citizen",
        enterprise: "Enterprise",
        organization: "Organization",
      },
    },
    components: {
      upload: {
        drop: "Drop file here or",
        click: "click to upload",
        error: {
          notSupported: "File is not supported",
          tooBig: "File exceeded the limit of 5MB",
          both: "File exceeded the limit of 5MB and file is not supported",
          exceedsLimit: "You have reached the limit",
        },
      },
    },
    form: {
      notAvailabe: "Not available",
      button: {
        cancel: "CANCEL",
        saveDraft: "SAVE DRAFT",
        sendForm: "SEND FORM",
      },
      confirm:
        "I confirm the data to be correct and I agree to having my data stored",
      requiresAuthentication: "Requires authentication - Please log in",
    },
    transaction: {
      transactions: "Transactions",
      draftForms: "Draft forms",
      submittedForms: "Submitted forms",
      submitTime: "Submit Time",
      //draftForms: 'Draft forms',
      //submittedForms: 'Submitted forms',
      form: "Form",
      status: "Status",
      statuses: {
        status1: "Waiting for delivery to backend",
        status2: "Delivered to backend",
      },
    },
    drafts: {
      submitTime: "Submit Time",
      category: "Category",
      eService: "eService",
      form: "Form",
      operations: "Operations",
      edit: "Edit",
    },
    login: {
      caption: "Demo login",
      pleaseInput: "Please input",
      sSN: "SSN",
      firstname: "Firstname",
      lastname: "Lastname",
      loginButton: "Login",
    },
    userContent: {
      headerLogotitle: "Leimun kunta_en",
      frontpageTitle: "Leimun asiointi_en",
      frontpageSubtitle: "Tervetuloa Leimun kunnan sähköisiin asiointipalveluihin._en",
      frontpageParagraph: "Täältä löydät Leimun kunnan sähköiset palvelut. Palvelujen käyttö edellyttää vahvaa tunnistautumista. Tutustuthan asiointipalvelun tietosuojaselosteeseen._en",
    } as Dictionary<string>,

  },
  sv: {
    userContent: {
      headerLogotitle: "Leimun kunta_sv",
      frontpageTitle: "Leimun asiointi_sv",
      frontpageSubtitle: "Tervetuloa Leimun kunnan sähköisiin asiointipalveluihin._sv",
      frontpageParagraph: "Täältä löydät Leimun kunnan sähköiset palvelut. Palvelujen käyttö edellyttää vahvaa tunnistautumista. Tutustuthan asiointipalvelun tietosuojaselosteeseen._sv",
    } as Dictionary<string>,
  },
};
