<template>
<div>
  <b-form-checkbox
    :id="'component_' + component.id"
    v-model="component.value"
    :state="getState()"
    @change="onChange()"
    @blur.native="onBlur()"
    lazy
    >{{ text() }}</b-form-checkbox
  >
  <b-form-invalid-feedback :state="getState()">{{invalidFeedback}}</b-form-invalid-feedback>
</div>
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
export default {
  mixins: [extendComponent],
    data() {
    return {
      validFeedback: "",
      invalidFeedback: "",
    };
  },  methods: {
    validate() {
      //check required
      if (this.component.required && !this.component.value) {
        this.invalidFeedback = "Tämä kenttä on pakollinen.";
        this.isValid = false;
        return;
      }
      this.invalidFeedback = "";
      this.isValid = true;
    },
  },
};
</script>
