




















































import { Component, Vue } from "vue-property-decorator";
import { Case } from "@/models/Case";
import moment from "moment";
import { MenuItem } from "@/models/MenuItem";

@Component
export default class ViewCases extends Vue {
  private cases: Array<Case> = [];
  private loading = true;

  private items: Record<string, any> = [];
  private fields: Record<string, any> = [];
  private filter = "";

  private totalRows = 0;
  private perPage = 20;
  private currentPage = 1;

  created() {
    this.getCases();
    this.fields = [
      {
        key: "casedate",
        label: "Avauspvm",
        sortable: true,
        formatter: (value: any, key: any, item: any) => {
          return moment(value).format("D.M.YYYY");
        },
      },
      { key: "diarynumber", label: "Asiatunnus", sortable: true },
      {
        key: "statusext",
        label: "Tila",
        sortable: true,
        formatter: (value: any, key: any, item: any) => {
          return this.$te("transaction.extStatus.extStatus"+value) ? this.$t("transaction.extStatus.extStatus"+value) : ""
        },
      },
      { key: "Actions", label: "", sortable: false },
    ];
  }

  public getCases(): void {
    this.cases = this.$store.state.cases;

    if (!this.cases) {
      this.$api
        .GetCases()
        .then((response) => {
          this.cases = this.$store.state.cases = response.data;
          this.initLists();
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      this.initLists();
    }
  }

  public initLists() {
    this.cases.forEach((_case) => {
      this.items.push({
        id: _case.CaseId,
        diarynumber: _case.DiaryNumber,
        casedate: _case.CaseDate,
        statusext: _case.StatusExt,
      });
    });
    this.totalRows = this.cases.length;
    this.currentPage = 1;
    this.loading = false;
  }

  public selectItem(item: Record<string, any>) {
    this.$router.push("/own/case/" + item.id);
  }

  public onFiltered(filteredItems: any) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
}
