







































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

@Component
export default class ViewDrafts extends Vue {
  private items: Record<string, any> = [];
  private fields: Record<string, any> = [];
  private filter = "";

  mounted() {
    this.getDrafts();
    this.fields = [
      {
        key: "SubmitTime",
        label: "Muokattu",
        sortable: true,
        formatter: (value: any, key: any, item: any) => {
          return moment
            .utc(value)
            .local()
            .format("D.M.yyyy HH.mm");
        },
      },
      { key: "EServiceName", label: "Palvelu", sortable: true },
      { key: "FormName", label: "Lomake", sortable: true },
      { key: "Actions", label: "", sortable: false },
    ];
  }

  public getDrafts(): void {
    this.$api
      .GetTransactions()
      .then((response) => {
        const drafts = response.data;
        drafts.forEach((draft: any) => {
          this.items.push({
            Id: draft.Guid,
            EServiceId: draft.EServiceId,
            FormId: draft.FormId,
            SubmitTime: draft.SubmitTime,
            EServiceName: this.$helpers.ParseName(
              draft.EServiceName,
              this.$i18n.locale
            ),
            FormName: this.$helpers.ParseName(draft.Name, this.$i18n.locale),
          });
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  public deleteDraft(id: string) {
    this.$api
      .DeleteDraft(id)
      .then((response) => {
        const index = this.items.findIndex((x: { Id: string }) => x.Id == id);
        if (index > -1) {
          this.items.splice(index, 1);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }
}
