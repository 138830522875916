







import { Component, Vue } from 'vue-property-decorator';
import Welcome from '@/components/Welcome.vue';
import EServices from '@/components/EServices.vue';

@Component({
  components: {
     Welcome, EServices
  },
})
export default class Home extends Vue {}
