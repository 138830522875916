































import { Component, Vue } from "vue-property-decorator";
import { MenuItem } from "@/models/MenuItem";

@Component
export default class OwnPage extends Vue {
  private caseSelected = false;
  public goto(route: string) {
    this.$store.state.selectedMenuItem = null;
    this.$router.push(route);
  }
  public selectMenuItem(item: MenuItem) {
    this.$store.state.selectedMenuItem = item;
    this.$router.push("/own/case/" + item.id);
  }
  public closeMenuItem(item: MenuItem) {
    if(item == this.$store.state.selectedMenuItem) {
      this.$store.state.selectedMenuItem = null;
      this.$router.push("/own/cases");
    }
    this.$store.state.menuItems = this.$store.state.menuItems.filter(
      (x: MenuItem) => x != item
    );
  }
  get showDrafts() {
    return this.$route.name == "drafts" && !this.$store.state.selectedMenuItem;
  }
  get showCases() {
    return this.$route.name == "cases" && !this.$store.state.selectedMenuItem;
  }
}
