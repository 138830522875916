<template>
  <b-container class="pt-3">
    <div
      v-if="!formSent && Object.keys(form).length > 0 && !isLoading"
      class="form"
    >
      <!-- <div class="breadcrumbs">
        <span class="item" @click="backToEServices">{{ eServices }}</span>
        <span class="divider">></span>
        <span class="item selected">{{ languageName(eServiceName) }}</span>
      </div> -->
      <b-row>
        <b-col>
          <h4 class="title">
            {{ languageName(form.metadata.name).toUpperCase() }}
          </h4>
        </b-col>
        <b-col>
          <b-form-select small v-model="selectedLanguage" right>
            <b-form-select-option
              v-for="language of form.metadata.supportedLanguages"
              :key="language.value"
              :value="language"
              :label="$helpers.ParseName(language.label, $i18n.locale)"
            />
          </b-form-select>
        </b-col>
      </b-row>
      <p class="description">{{ languageName(form.metadata.description) }}</p>

      <b-form>
        <b-nav tabs class="pt-2 pl-2">
          <b-nav-item
            @click="selectSection(null, 0)"
            :active="sectionIndex == 0"
            :disabled="sectionIndex == 0"
            >1. Lähettäjän tiedot</b-nav-item
          >
          <b-nav-item
            v-for="(section, index) of form.sections"
            :key="section.id"
            @click="selectSection(section, index + 1)"
            :active="activeSection == section"
            :disabled="activeSection == section"
          >
            {{ index + 2 }}. {{ languageName(section.title) }}
            <b-icon-exclamation-circle
              class="ml-1"
              style="color: #dc3545;"
              v-if="section.inError"
            ></b-icon-exclamation-circle>
          </b-nav-item>
        </b-nav>

        <UserInfo ref="userInfo" v-if="sectionIndex == 0" :mode="'readonly'" />

        <form-section
          ref="formSection"
          v-if="activeSection"
          :section="activeSection"
          :selected-language="selectedLanguage.value"
          :formDirty="formDirty"
        />

        <!-- <div v-if="sectionIndex == form.sections.length">
          <b-form-checkbox v-model="confirm">{{
            $t("form.confirm")
          }}</b-form-checkbox>
        </div> -->

        <b-button-toolbar
          class="mt-4 mb-4"
          key-nav
          aria-label="Toolbar with button groups"
        >
          <b-button variant="outline-primary" @click="$router.go(-1)">{{
            $t("form.button.cancel")
          }}</b-button>
          <b-button
            variant="primary"
            class="ml-3"
            v-if="isAuthenticated"
            @click="saveForm(true)"
            >{{ $t("form.button.saveDraft") }}</b-button
          >
          <b-button
            v-if="sectionIndex < form.sections.length"
            class="ml-auto"
            variant="primary"
            @click="nextSection"
            >JATKA</b-button
          >
          <b-button
            v-if="sectionIndex == form.sections.length"
            class="ml-auto"
            variant="primary"
            :disabled="sendingForm"
            @click="saveForm(false)"
            >{{ $t("form.button.sendForm") }}
            <b-spinner
              v-if="sendingForm"
              variant="light"
              small
              label="Loading..."
            ></b-spinner>
          </b-button>
        </b-button-toolbar>
      </b-form>

      <!-- <div class="button-group clearfix">
        <div @click="$router.go(-1)" class="button secondary">
          {{ $t("form.button.cancel") }}
        </div>
        <div v-if="isAuthenticated" @click="submitDraftFormUI" class="button">
          {{ $t("form.button.saveDraft") }}
        </div>
        <div
          class="button send"
          :class="
            (validation.length > 0
            ? !confirm ||
              validation.length !==
                validation.filter((x) => x.isValid === true).length
            : !confirm)
              ? 'disabled'
              : ''
          "
          @click="submitFormUI"
        >
          {{ $t("form.button.sendForm") }}
        </div>
      </div> -->
    </div>
    <div v-if="formSent">
      <b-card no-body class="overflow-hidden" style="max-width: 540px;">
        <b-row no-gutters>
          <b-col md="4" style="font-size:7em; color:green; text-align: center">
            <b-icon-check-circle />
          </b-col>
          <b-col md="8">
            <b-card-body title="Lomakkeen lähetys onnistui!">
              <b-card-text>
                Saat viestin kun asia on käsittelyssä.
              </b-card-text>
              <b-card-text>
                <b-button variant="outline-primary" @click="$router.go(-1)"
                  >Palaa</b-button
                >
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import FormSection from "@/components/form/FormSection";
import EventBus from "@/eventbus";
import Auth from "@/auth/index";
import UserInfo from "@/components/UserInfo";
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      form: {},
      selectedLanguage: {},
      attachments: [],
      answerLanguage: {},
      isLoading: true,
      confirm: false,
      isDirty: false,
      validation: [],
      getFormFailed: false,
      api: null,
      helpers: null,
      draftId: null,
      eServiceId: null,
      formId: null,
      activeSection: null,
      sectionIndex: 0,
      formDirty: false,
      sendingForm: false,
      formSent: false,
      transaction: null
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    eServiceName: {
      type: Array,
      default() {
        return [];
      },
    },
    caseId: {
      type: String,
      default: "",
    },
  },
  watch: {
    getFormFailed() {
      if (this.getFormFailed) {
        this.$bvToast.toast(this.$t("messages.getFormError"), {
          title: "",
          toaster: "b-toaster-top-center",
          solid: true,
          variant: "danger",
        });
      }
    },
    selectedLanguage: {
      handler(val) {
        if (val) {
          this.form.settings.answerLanguage = val.value;
        }
      },
      deep: true,
    },
    validation(newVal) {
      this.validation = newVal;
    },
  },
  computed: {
    hasAttachments() {
      return this.attachments.length > 0 ? true : false;
    },
    eServices() {
      return (
        this.$t("header.navbar.eservices")
          .substring(0, 1)
          .toUpperCase() +
        this.$t("header.navbar.eservices")
          .substring(1)
          .toLowerCase()
      );
    },
    isAuthenticated() {
      return true; //todo Auth.getUser().loggedIn;
    },
  },
  methods: {
    selectSection(section, index) {
      this.activeSection = section;
      this.sectionIndex = index;
    },
    nextSection() {
      // if (this.sectionIndex == 0) {
      //   this.$refs.userInfo.save();
      // }
      if (this.sectionIndex > 0 && !this.$refs.formSection.isValid()) {
        this.$bvToast.toast("Tarkista lomakkeen kentät.", {
          title: "",
          toaster: "b-toaster-top-center",
          solid: true,
          variant: "warning",
        });
        return;
      }
      this.activeSection = this.form.sections[this.sectionIndex];
      this.sectionIndex++;
      window.scrollTo(0, 0);
    },
    getTransaction() {
      this.isLoading = true;
      this.confirm = false;
      this.getFormFailed = false;
      if(this.$route.params.transactionId) {
        this.$api
          .GetTransaction(
            this.$route.params.transactionId,
          )
          .then((response) => {
            this.transaction = response.data;
            this.initForm(this.transaction.SubmissionData);
            this.isLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.getFormFailed = true;
          });

      }
      else if (this.$route.params.eServiceShortCode && this.$route.params.formShortCode) {
        this.$api
          .CreateTransactionByShortCode(
            this.$route.params.eServiceShortCode,
            this.$route.params.formShortCode
         )
          .then((response) => {
            //console.log(response);
            this.transaction = response.data;
            this.initForm(this.transaction.SubmissionData);
            this.isLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.getFormFailed = true;
          });
      }
      else if (this.$route.params.eServiceId && this.$route.params.formId) {
        this.$api
          .CreateTransaction(
            this.$route.params.eServiceId,
            this.$route.params.formId,
            this.$route.params.parentTransactionId || "null"
          )
          .then((response) => {
            //console.log(response);
            this.transaction = response.data;
            this.initForm(this.transaction.SubmissionData);
            this.isLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.getFormFailed = true;
          });
      }
    },
    // getAttachments() {
    //   let attachments = [];
    //   this.form.sections.forEach((section) => {
    //     section.fieldBlocks.forEach((fieldBlock) => {
    //       fieldBlock.columnData.forEach((cdata) => {
    //         cdata.components.forEach((component) => {
    //           if (component.files) {
    //             attachments = attachments.concat(component.files);
    //           }
    //         });
    //       });
    //     });
    //   });
    //   return attachments;
    // },
    initForm(schema) {
      this.form = JSON.parse(schema);
      this.selectedLanguage = this.form.metadata.defaultLanguage;
      this.form.settings.answerLanguage = this.selectedLanguage.value;
    },
    // createSubmittedTransaction(id) {
    //   const transaction = {
    //     Id: id,
    //     EServiceId: this.eServiceId,
    //     FormId: this.formId,
    //     SubmissionData: JSON.stringify(this.form),
    //     //public api set this
    //     //SubmitTime: new Date(),
    //     //public api set this
    //     //SSN: loginData.sSN,
    //     HasAttachments: this.hasAttachments,
    //   };
    //   return transaction;
    // },
    saveForm(draft) {
      if (!draft && !this.$refs.formSection.isValid()) {
        this.$bvToast.toast("Tarkista lomakkeen kentät.", {
          title: "",
          toaster: "b-toaster-top-center",
          solid: true,
          variant: "warning",
        });
        return;
      }
      if (!draft && !this.checkFormValid()) {
        this.$bvToast.toast("Tarkista lomakkeen kentät.", {
          title: "",
          toaster: "b-toaster-top-center",
          solid: true,
          variant: "warning",
        });
        return;
      }

      // alert("Dynasty rajapinta alhaalla, tallennus epäonnistui");
      // return; //todo, rajapinta alhaalla

      if(!draft)
        this.sendingForm = true;

      //const id = uuidv4();
      //const transaction = this.createSubmittedTransaction(id);
      // if (this.$route.params.parentTransactionId)
      //   this.transaction.ParentTransactionId = this.$route.params.parentTransactionId;

      if(!draft)
        this.transaction.Status = 1;  

      this.transaction.SubmissionData = JSON.stringify(this.form);

      this.$api
        .SaveTransaction(this.transaction)
        .then((response) => {
          //this.submitAttachments(this.transaction.Id);
          if(draft)
            this.draftSaved(true);
          else
            this.formSaved(true);
        })
        .catch((e) => {
          if(draft)
            this.draftSaved(false);
          else
            this.formSaved(false);
        });
    },
    // submitAttachments(transactionId) {
    //   const attachments = this.getAttachments();
    //   if (attachments) {
    //     this.$api
    //       .PostTransactionAttachments(transactionId, attachments)
    //       .then((response) => {
    //         this.formSaved(true);
    //       })
    //       .catch((e) => {
    //         this.formSaved(false);
    //       });
    //   } else {
    //     this.formSaved(true);
    //   }
    // },
    formSaved(ok) {
      this.sendingForm = false;
      if (ok) {
        // this.$bvToast.toast(this.$t("messages.sendFormOk"), {
        //   title: "",
        //   toaster: "b-toaster-top-center",
        //   solid: true,
        //   variant: "success",
        // });
        this.formSent = true;
      } else {
        this.$bvToast.toast(this.$t("messages.sendFormError"), {
          title: "",
          toaster: "b-toaster-top-center",
          solid: true,
          variant: "danger",
        });
      }
    },

    // createDraftForm(id) {
    //   if (id) this.draftId = id;
    //   else this.draftId = uuidv4();
    //   const draftForm = {
    //     Id: this.draftId,
    //     EServiceId: this.eServiceId,
    //     FormId: this.formId,
    //     SubmissionData: JSON.stringify(this.form),
    //     //public api set this
    //     //SubmitTime: new Date(),
    //     //public api set this
    //     //SSN: loginData.SSN,
    //     HasAttachments: this.hasAttachments,
    //   };
    //   return draftForm;
    // },
    // submitDraftForm() {
    //   // const notvalidValidations = this.validation.filter(
    //   //   (x) => x.isValid === false
    //   // );
    //   // //some field found which is not valid so cannot submit draft then
    //   // if (notvalidValidations.length > 0) return;

    //   console.log("submitDraftForm");

    //   if (this.$route.params.draftId) {
    //     //update form
    //     const draftForm = this.createDraftForm(this.$route.params.draftId);
    //     this.$api
    //       .PutDraftForm(draftForm)
    //       .then((response) => {
    //         this.submitDraftFormAttachments(draftForm.Id);
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //         this.draftSaved(false);
    //       });
    //   } else {
    //     //new form
    //     const draftForm = this.createDraftForm();
    //     this.$api
    //       .PostDraftForm(draftForm)
    //       .then((response) => {
    //         this.submitDraftFormAttachments(draftForm.Id);
    //       })
    //       .catch((e) => {
    //         this.draftSaved(false);
    //       });
    //   }
    // },
    // submitDraftFormAttachments(draftFormId) {
    //   this.form.sections.forEach((section) => {
    //     section.fieldBlocks.forEach((fieldBlock) => {
    //       fieldBlock.columnData.forEach((cdata) => {
    //         cdata.components.forEach((component) => {
    //           if (component.files) {
    //             component.files.forEach(file => {
    //             this.$api
    //               .AddAttachment(draftFormId, component.id, file)
    //               .then((response) => {
    //                 console.log('file attached');
    //               })
    //               .catch((e) => {
    //                 console.log('error saving attachment: '+e);
    //                 this.draftSaved(false);
    //                 return;
    //               });
    //             });
    //           }
    //         });
    //       });
    //     });
    //   });
    //   this.draftSaved(true);

    //   // const attachments = this.getAttachments();
    //   // if (attachments) {
    //   //   attachments.forEach((element) => {
    //   //     this.$api
    //   //       .AddAttachment(draftFormId, attachments)
    //   //       .then((response) => {
    //   //         this.draftSaved(true);
    //   //       })
    //   //       .catch((e) => {
    //   //         this.draftSaved(false);
    //   //       });
    //   //   });
    //   // } else {
    //   //   this.draftSaved(true);
    //   // }

    //   // this.$api
    //   //   .PostDraftFormAttachments(draftFormId, this.attachments)
    //   //   .then((response) => {
    //   //         this.draftSaved(true);
    //   //   })
    //   //   .catch((e) => {
    //   //         this.draftSaved(false);
    //   //   });
    // },
    draftSaved(ok) {
      if (ok) {
        this.$bvToast.toast(this.$t("messages.sendFormDraftOk"), {
          title: "",
          toaster: "b-toaster-top-center",
          solid: true,
          variant: "success",
        });
      } else {
        this.$bvToast.toast(this.$t("messages.sendFormDraftError"), {
          title: "",
          toaster: "b-toaster-top-center",
          solid: true,
          variant: "danger",
        });
      }
    },

    languageName(name) {
      return this.$helpers.ParseName(name, this.selectedLanguage.value);
    },
    currentAttachments(val) {
      this.attachments = val;
    },
    backToEServices() {
      EventBus.$emit("form:return", false);
    },
    checkFormValid() {
      let formValid = true;
      this.form.sections.forEach((section) => {
        section.fieldBlocks.forEach((fieldBlock) => {
          fieldBlock.columnData.forEach((cdata) => {
            cdata.components.forEach((component) => {
              component.isDirty = true;
              if (component.isValid == false) {
                fieldBlock.inError = true;
                section.inError = true;
                formValid = false;
              }
            });
          });
        });
      });
      return formValid;
    },
  },
  created() {
    this.getTransaction();
  },
  mounted() {
    EventBus.$on("validation:isValid", (field) => {
      if (this.validation.filter((x) => x.id === field.id).length === 0) {
        this.validation.push(field);
      } else {
        this.validation.filter((x) => x.id === field.id)[0].isValid =
          field.isValid;
      }
    });

    EventBus.$on("form:emailReceiver", (emailReceiver) => {
      this.form.settings.emailReceiver = emailReceiver;
    });
  },
  components: {
    FormSection,
    UserInfo,
    //Loading,
  },
};
</script>
