





































import { Component, Vue, Watch } from "vue-property-decorator";
import Navbar from "@/components/Navbar.vue";
import * as signalR from "@microsoft/signalr";
import store from "./store";
import api from "@/api/Api";

const connection = new signalR.HubConnectionBuilder()
    .withUrl(api.MessageHubUrl())
    .build();

connection.on("NewMessage", () => {
  console.log("NewMessage");
    if (store.state.session) store.dispatch("checkMessages");
});

connection.start().catch(err => console.log(err));

@Component({
  components: {
    Navbar,
  },
  metaInfo() {
    return {
      title: this.$t('userContent.frontpageTitle')+"", //todo oma kielitermi
    };
  },
})
export default class App extends Vue {
  private polling = 0;

  created() {
    this.setLocale();
    this.setFavicon();
    this.checkMessages();
    //this.pollData();
  }

  setLocale() {
    const html = document.documentElement;
    html.setAttribute("lang", this.$i18n.locale);
  }

  setFavicon() {
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = this.$api.FaviconUrl();
    document.getElementsByTagName('head')[0].appendChild(link);

}  

  @Watch("$i18n.locale", { immediate: false, deep: true })
  onLocaleChange(newVal: any) {
    this.setLocale();
  }

  // pollData() {
  //   this.polling = setInterval(() => {
  //     this.checkMessages();
  //   }, 10 * 1000); //todo: hae harvemmin
  // }

  checkMessages() {
    if (this.$store.state.session) this.$store.dispatch("checkMessages");
  }

  beforeDestroy() {
    clearInterval(this.polling);
  }

}
