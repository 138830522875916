<template>
  <b-form-input
    :id="'component_' + component.id"
    :type="type"
    v-model="component.value"
    :state="getState()"
    :placeholder="placeholder()"
    @change="onChange()"
    @blur="onBlur()"
    lazy
    maxlength=2000
  />
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
import { FinnishBusinessIds } from '@/helpers/finnish-business-ids'
import {isValidPersonId} from '@/helpers/hetu';

export default {
  mixins: [extendComponent],

  // created () {
  // },

  methods: {
    validate() {
      //check required
      if (this.component.required && !this.component.value) {
        this.onValidation("Tämä kenttä on pakollinen.");
        this.component.isValid = this.isValid = false;
        return;
      }

      //check email
      if (
        this.component.validation == "email" &&
        (this.component.required || this.component.value) &&
        !this.$helpers.isValidEmail(this.component.value)
      ) {
        this.onValidation("Tarkista sähköposti");
        this.component.isValid = this.isValid = false;
        return;
      }

      //check bic
      if (
        this.component.validation == "bic" &&
        (this.component.required || this.component.value) &&
        !FinnishBusinessIds.isValidBusinessId(this.component.value)
      ) {
        this.onValidation("Tarkista y-tunnus");
        this.component.isValid = this.isValid = false;
        return;
      }

      //check ssn
      if (
        this.component.validation == "ssn" &&
        (this.component.required || this.component.value) &&
        !isValidPersonId(this.component.value)
      ) {
        this.onValidation("Tarkista henkilötunnus");
        this.component.isValid = this.isValid = false;
        return;
      }


      this.onValidation("");
      this.component.isValid = this.isValid = true;
    },
  },

  computed: {
    type() {
      return this.component.validation == "email" ? "email" : "text";
    },
  },
};
</script>
