




import { Component, Vue } from "vue-property-decorator";
import Settings from "@/components/Settings.vue";

@Component({
  components: {
     Settings
  },
})export default class ViewSettings extends Vue {
}
