<template>
  <div>
    <section-field-block
      v-for="block of section.fieldBlocks"
      :key="block.key"
      :field-block="block"
      :selected-language="selectedLanguage"
      :sectionDirty="sectionDirty"
    />
  </div>
</template>

<script>
import SectionFieldBlock from "@/components/form/SectionFieldBlock";

export default {
  data() {
    return {
      sectionDirty: false,
      sectionValid: true,
    };
  },
  props: {
    section: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedLanguage: {
      type: String,
      default: "",
    },
    formDirty: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isValid() {
      // console.log(this.section);
      // this.section.fieldBlocks.forEach(block => {
      //   block.columnData.forEach(column => {
      //     column.components.forEach(component => {
      //       component.isDirty = true;
      //     });
      //   });
      // });
      let sectionValid = true;
      this.section.inError = false;
      this.sectionDirty = true;
      this.section.fieldBlocks.forEach((fieldBlock) => {
        fieldBlock.columnData.forEach((cdata) => {
          cdata.components.forEach((component) => {
            component.isDirty = true;
            if (component.isValid == false) {
              fieldBlock.inError = true;
              this.section.inError = true;
              sectionValid = false;
            }
          });
        });
      });

      return sectionValid;
    },
  },
  components: {
    SectionFieldBlock,
  },
};
</script>
<style scoped>
.scroll {
  max-height: 500px;
  overflow-y: auto;
}
</style>
