<template>
  <b-form-datepicker
    :id="'component_' + component.id"
    class="w-100"
    v-model="component.value"
    type="date"
    format="dd.MM.yyyy"
    :state="getState()"
    @change="onChange()"
    @blur.native="onBlur()"
    lazy
    :locale="$i18n.locale"
    v-bind="labels[$i18n.locale] || {}"
  />
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
export default {
  data() {
    return {
      value: "",
      showDecadeNav: false,
      hideHeader: false,
      labels: {
        fi: {
          labelPrevDecade: "Edellinen vuosikymmen",
          labelPrevYear: "Edellinen vuosi",
          labelPrevMonth: "Edellinen kuukausi",
          labelCurrentMonth: "Tämä kuukausi",
          labelNextMonth: "Seuraava kuukausi",
          labelNextYear: "Seuraava vuosi",
          labelNextDecade: "Seuraava vuosikymmen",
          labelToday: "Tänään",
          labelSelected: "Valittu päivä",
          labelNoDateSelected: "Valitse päivä",
          labelCalendar: "Kalenteri",
          labelNav: "Kalenterin navigaatio",
          labelHelp: "Voit käyttää nuolinäppäimiä päivän valintaan",
        },
      },
    };
  },
  mixins: [extendComponent],
  methods: {
    validate() {
      //check required
      if (this.component.required && !this.component.value) {
        this.onValidation("Tämä kenttä on pakollinen.");
        this.isValid = false;
        return;
      }
      this.onValidation("");
      this.isValid = true;
    },

  },
};
</script>
