






import { Component, Vue } from "vue-property-decorator";
import UserInfo from "@/components/UserInfo.vue";

@Component({
  components: {
    UserInfo,
  },
})
export default class ViewUserinfo extends Vue {}
