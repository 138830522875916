
















































import { Component, Vue } from "vue-property-decorator";
import { Case } from "@/models/Case";
import { Message } from "@/models/Message";

@Component
export default class Welcome extends Vue {
  private cases!: Array<Case>;
  private messages!: Array<Message>;
  private loadingMessages = false;
  private loadingCases = false;

  created() {
    if (this.$store.state.session) {
      this.getMessages();
      this.getCases();
    }
  }

  public getMessages(): void {
    this.messages = this.$store.state.messages;
    if (!this.messages) {
      this.loadingMessages = true;
      this.$api
        .GetMessages()
        .then((response) => {
          this.messages = this.$store.state.messages = response.data;
          this.loadingMessages = false;
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.loadingMessages = false;
        });
    }
  }

  public getCases(): void {
    this.cases = this.$store.state.cases;

    if (!this.cases) {
      this.loadingCases = true;
      this.$api
        .GetCases()
        .then((response) => {
          this.cases = this.$store.state.cases = response.data;
          this.loadingCases = false;
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.loadingCases = false;
        });
    }
  }

  public login(): void {
    this.$api.SuomiFiLogin(this.$route.query.r).then((response) => {
      window.location.href = response.data;
    });
  }
}
