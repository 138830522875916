
































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Case } from "@/models/Case";
import { Transaction } from "@/models/Transaction";
import { MenuItem } from "@/models/MenuItem";

@Component
export default class ViewCase extends Vue {
  private _case!: Case;
  private transactions!: Array<Transaction>;
  private transaction!: Transaction;
  private eService!: any;
  private loading = true;
  private showCase = false;
  private showDocs = false;
  private showEService = false;
  private key = 1;

  created() {
    this.loading = true;
    this.showCase = false;
    this.showEService = false;
    this.getCase();
    this.getTransactions();
  }

  public getCase(): void {
    this.loading = true;
    this.showCase = false;
    this.eService = null;

    if (this.$route.params.caseid) {
      this.getFromStore()
        .then((response) => {
          this._case = response;
          this.checkMenuItem();
          this.showCase = true;
          this.loading = false;
          if (!this._case.Documents) this.getFromDynasty(true);
          else this.showDocs = true;
        })
        .catch((error) => {
          this.getFromDynasty(false);
        });
    }
  }

  public checkMenuItem() {
    const menuItems = this.$store.state.menuItems as Array<MenuItem>;
    let menuItem = menuItems.find((x) => x.id == this._case.CaseId) as MenuItem;

    if (!menuItem) {
      menuItem = new MenuItem();
      menuItem.id = "" + this._case.CaseId;
      menuItem.title = "Asia: " + this._case.DiaryNumber;
      menuItems.push(menuItem);
      this.$store.commit("updateMenuItems", menuItems);
    }

    this.$store.state.selectedMenuItem = menuItem;
  }

  public getFromStore() {
    //todo, siirrä apiin?
    return new Promise<Case>((resolve, reject) => {
      const caseInList: Case = this.$store.state.cases.find(
        (x: Case) => x.CaseId == +this.$route.params.caseid
      );

      if (caseInList) resolve(caseInList);
      else reject();
    });
  }

  public getFromDynasty(updateDocsOnly: boolean) {
    this.$api
      .GetCase(+this.$route.params.caseid)
      .then((response) => {
        if (updateDocsOnly) this._case.Documents = response.data.Documents;
        else this._case = response.data;
        this.showDocs = true;
        this.checkMenuItem();
        this.loading = false;
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  public getTransactions(): void {
    //this.loading = true;
    this._case = new Case();
    if (this.$route.params.caseid) {
      this.$api
        .GetTransactionsByCase(+this.$route.params.caseid)
        .then((response) => {
          if (response.data) {
            this.transaction = response.data;
            this.getEService(this.transaction.EServiceId);
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }
  }

  public getEService(eServiceId: string | undefined) {
    if (!this.showEService && eServiceId) {
      //haetaan vain ensimmäinen?
      this.$api
        .GetEService(eServiceId)
        .then((response) => {
          this.eService = response.data;
          this.showEService = true;
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }
  }
}
