



























import { Component, Vue } from "vue-property-decorator";

@Component
export default class Settings extends Vue {
  public logout(): void {
    this.$api.SuomiFiLogout().then((response) => {
      window.location.href = response.data;
    });
  }
}
