<template>
  <b-col>
    <b-form-group
    class="w-100"
          label-cols-sm="3"
          label-align-sm="right"
      v-if="component.inputType !== 'title' && component.inputType !== 'info'"
      :description="info()"
      :label="label()"
      :label-for="'component_' + component.id"
      :valid-feedback="validFeedback"
      :invalid-feedback="invalidFeedback"
    >
      <component-text
        v-if="component.inputType === 'text'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-number
        v-if="component.inputType === 'number'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-checkbox
        v-if="component.inputType === 'checkbox'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-radio
        v-if="component.inputType === 'radio'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-textarea
        v-if="component.inputType === 'textarea'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-date
        v-if="component.inputType === 'date'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-datetime
        v-if="component.inputType === 'datetime'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-select
        v-if="component.inputType === 'select'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-currency
        v-if="component.inputType === 'currency'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-optionset
        v-if="component.inputType === 'optionset'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
      <component-upload
        v-if="component.inputType === 'upload'"
        :component="component"
        :onValidation="onValidation"
        :selected-language="selectedLanguage"
        :blockDirty="blockDirty"
      />
    </b-form-group>
    <component-title
      v-if="component.inputType === 'title'"
      :component="component"
      :selected-language="selectedLanguage"
    />
    <component-info
      v-if="component.inputType === 'info'"
      :component="component"
      :selected-language="selectedLanguage"
    />
  </b-col>
</template>

<script>
import ComponentText from "@/components/form/components/ComponentText";
import ComponentNumber from "@/components/form/components/ComponentNumber";
import ComponentCheckbox from "@/components/form/components/ComponentCheckbox";
import ComponentRadio from "@/components/form/components/ComponentRadio";
import ComponentTextarea from "@/components/form/components/ComponentTextarea";
import ComponentDate from "@/components/form/components/ComponentDate";
import ComponentDatetime from "@/components/form/components/ComponentDatetime";
import ComponentSelect from "@/components/form/components/ComponentSelect";
import ComponentCurrency from "@/components/form/components/ComponentCurrency";
import ComponentOptionset from "@/components/form/components/ComponentOptionset";
import ComponentTitle from "@/components/form/components/ComponentTitle";
import ComponentInfo from "@/components/form/components/ComponentInfo";
import ComponentUpload from "@/components/form/components/ComponentUpload";

export default {
  props: {
    component: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedLanguage: {
      type: String,
      default: "",
    },
    blockDirty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validFeedback: "",
      invalidFeedback: "",
    };
  },
  methods: {
    label() {
      if (this.component.label) {
        return this.component.label.find((x) => {
          return x.language === this.selectedLanguage;
        }) !== undefined
          ? this.component.label.find((x) => {
              return x.language === this.selectedLanguage;
            }).text
          : "";
      } else {
        return "n/a";
      }
    },
    info() {
      if (this.component.info) {
        return this.component.info.find((x) => {
          return x.language === this.selectedLanguage;
        }) !== undefined
          ? this.component.info.find((x) => {
              return x.language === this.selectedLanguage;
            }).text
          : "";
      } else {
        return "";
      }
    },
    onValidation(val) {
      this.invalidFeedback = val;
    },
  },
  components: {
    ComponentText,
    ComponentNumber,
    ComponentCheckbox,
    ComponentRadio,
    ComponentTextarea,
    ComponentDate,
    ComponentDatetime,
    ComponentSelect,
    ComponentCurrency,
    ComponentOptionset,
    ComponentTitle,
    ComponentInfo,
    ComponentUpload,
  },
};
</script>
