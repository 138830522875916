import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Form from "../views/Form.vue";
import OwnPage from "../views/OwnPage.vue";
import ViewDrafts from "../views/ViewDrafts.vue";
import ViewCases from "../views/ViewCases.vue";
import ViewCase from "../views/ViewCase.vue";
import ViewUserInfo from "../views/ViewUserInfo.vue";
import ViewOrgInfo from "../views/ViewOrgInfo.vue";
import ViewMessages from "../views/ViewMessages.vue";
import ViewSettings from "../views/ViewSettings.vue";
import ViewLogout from "../views/ViewLogout.vue";
import Login from "../views/Login.vue";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/drafts",
    name: "drafts",
    component: ViewDrafts,
  },
  {
    path: "/drafts/form/:transactionId",
    name: "draft",
    component: Form,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/own",
    name: "OwnPage",
    component: OwnPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "cases",
        name: "cases",
        component: ViewCases,
      },
      {
        path: "case/:caseid",
        name: "case",
        component: ViewCase,
      },
    ],
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/forms/:eServiceId/:formId/:parentTransactionId",
    name: "formforcase",
    component: Form,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:eServiceShortCode/:formShortCode",
    name: "form",
    component: Form,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/forms/:eServiceId/:formId",
    name: "form",
    component: Form,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/userinfo",
    name: "userinfo",
    component: ViewUserInfo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orginfo",
    name: "orginfo",
    component: ViewOrgInfo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/messages",
    name: "messages",
    component: ViewMessages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: ViewSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: ViewLogout,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   store.state.session

// });

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.state.session) {
      next();
    } else {
      next("/login?r="+to.path);
    }
  } else {
    next();
  }
});

export default router;
