<template>
  <div>
    <b-form-file
      multiple
      v-model="filestoadd"
      :placeholder="
        $t('components.upload.drop') + ' ' + $t('components.upload.click')
      "
      :id="component.id"
      :name="component.id"
      :ref="'fileinput' + component.id"
      :state="getState()"
    >
    </b-form-file>
    <b-form-invalid-feedback :state="getState()">{{
      invalidFeedback
    }}</b-form-invalid-feedback>
    Ladatut tiedostot:
    <ul>
      <li v-for="attachment of files" :key="attachment.Guid">
        {{ attachment.FileName }}
        <b-button @click="removeAttachment(attachment)">poista</b-button>
      </li>
    </ul>
    <b-spinner v-if="fileLoading" variant="info" label="Loading..."></b-spinner>
  </div>
</template>

<script>
import extendComponent from "@/mixins/extendComponent";
// import EventBus from "@/eventbus";
//import { component } from 'vue/types/umd';

export default {
  mixins: [extendComponent],
  data() {
    return {
      filestoadd: [],
      files: [],
      allowedTypes: "",
      fileCount: 0,
      maxSize: 5 * 1024 * 1024, //Max file size 5MB converted to bytes
      invalidFeedback: "",
      transactionId: null,
      fileLoading: false,
    };
  },

  created() {
    if (this.component.supportedTypes) {
      this.component.supportedTypes.forEach((fileType) => {
        this.allowedTypes += fileType + ",";
      });
    }

    this.component.files = this.files = [];

    this.transactionId = this.$parent.$parent.$parent.$parent.$parent.transaction.Guid;

    this.$api
      .GetAttachments(this.transactionId, this.component.id)
      .then((response) => {
        this.files = response.data;
        this.fileCount = this.files.length;
      })
      .catch((error) => {
        console.error("Error: ", error); //todo
      });
  },

  watch: {
    filestoadd(val) {
      val.forEach((file) => {
        this.addAttachment(file);
      });

      this.$refs["fileinput" + this.component.id].reset();
    },
  },

  methods: {
    addAttachment(file) {
      this.fileCount++;

      const fileType = file.name.substring(file.name.lastIndexOf("."));

      const sizeOk = this.maxSize > file.size;

      const fileTypeOk =
        this.component.supportedTypes.filter((x) => x === fileType).length > 0;

      const numberOfAttachmentFull = this.fileCount > this.component.limit;

      //onsole.log("this.fileCount:" + this.fileCount);
      //console.log("this.component.limit:" + this.component.limit);

      if (numberOfAttachmentFull) {
        this.setMessage(
          this.$t("components.upload.error.exceedsLimit") + this.component.limit
        );
        this.fileCount--;
        return;
      }

      if (sizeOk && fileTypeOk) {
        this.fileLoading = true;
        this.$api
          .AddAttachment(this.transactionId, this.component.id, file)
          .then((response) => {
            this.fileLoading = false;
            this.files.push(response.data);
            this.updateValue();
          })
          .catch((error) => {
            this.fileLoading = false;
            console.error("Error: ", error); //todo
          });
      } else {
        this.fileCount--;
        if (!fileTypeOk) {
          this.setMessage(
            this.$t("components.upload.error.notSupported") + this.allowedTypes
          );
        }
        if (!sizeOk) {
          this.setMessage(this.$t("components.upload.error.tooBig") + "5 MB"); //todo konf
        }
      }
    },
    removeAttachment(attachment) {
      this.$api
        .RemoveAttachment(attachment)
        .then((response) => {
          this.files = this.files.filter((x) => x !== attachment);
          this.updateValue();
          this.fileCount--;
        })
        .catch((error) => {
          console.error("Error: ", error); //todo
        });
    },
    updateValue() {
      this.component.value = "";
      this.files.forEach((file) => {
        this.component.value += file.FileName + " ";
      });
    },
    validate() {
      if (this.component.required && this.files.length == 0) {
        this.invalidFeedback = "Tämä kenttä on pakollinen.";
        this.isValid = false;
        return;
      }
      this.invalidFeedback = "";
      this.isValid = true;
    },
    setMessage(val) {
      this.$bvToast.toast(val, {
        title: "",
        toaster: "b-toaster-top-center",
        solid: true,
        variant: "danger",
      });
    },
  },
};
</script>

<style scoped>
.custom-file-label {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 360px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
</style>
