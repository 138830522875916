












































import { Component, Vue } from "vue-property-decorator";
import api from "@/api/Api";
import helpers from "@/helpers/Helpers";
import { EService } from "@/models/EService";

@Component
export default class EServices extends Vue {
  private eServices: Array<EService> = [];
  private filter = "";
  private loading = true;

  created() {
    this.getESErvices();
  }

  public getESErvices(): void {
    this.$api
      .GetServices()
      .then((response) => {
        this.eServices = response.data as EService[];
        this.loading = false;
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  get filteredEServices(): Array<EService> {
    if (this.filter)
      return this.eServices.filter(
        (x) =>
          this.$helpers
            .ParseName(x.Name, this.$i18n.locale)
            .toLowerCase()
            .includes(this.filter.toLowerCase()) ||
          this.$helpers
            .ParseName(x.Description, this.$i18n.locale)
            .toLowerCase()
            .includes(this.filter.toLowerCase()) || //todo lang from session (in helper?)
          x.Forms.filter((f) =>
            this.$helpers
              .ParseName(f.Name, this.$i18n.locale)
              .toLowerCase()
              .includes(this.filter.toLowerCase())
          ).length > 0
      );
    return this.eServices;
  }

  public checkCategory(role: number, category: string) 
  {
    //console.log("Role: "+role);
    //console.log("category: "+category);
    if(category == "Any")
      return true;
    else if(category == "Citizen" && role==1) //todo enum
      return true;
    else if(category == "Organization" && (role==2 || role==3))  
      return true;
    return false;
  }

  public resolveFormUrl(eService: any, form: any) {
    if(eService.ShortCode && form.ShortCode)
      return "/"+eService.ShortCode+"/"+form.ShortCode
    return "/forms/" + eService.Id + "/" + form.Id;
  }
}
