




import { Component, Vue } from "vue-property-decorator";
import Messages from "@/components/Messages.vue";

@Component({
  components: {
     Messages
  },
})export default class ViewMessages extends Vue {
}
