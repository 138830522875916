import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueI18n from "vue-i18n";
import { messages } from "./localization/messages";
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "fi",
  messages: messages,
  fallbackLocale: "fi",
});

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.directive("click-outside", {
  bind: function(el: any, binding, vnode: any) {
    el.clickOutsideEvent = function(event: any) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function(el: any) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

import api from "@/api/Api";
import helpers from "@/helpers/Helpers";
Vue.prototype.$api = api;
Vue.prototype.$helpers = helpers;

declare module "vue/types/vue" {
  interface Vue {
    $api: typeof api;
    $helpers: typeof helpers;
  }
}

//Vue.use(api);

Vue.config.productionTip = false;

function initSettings() {
  return new Promise((resolve) => {
    api
      .ContentData()
      .then((response: any) => {
        const contentData = response.data;
        for (const key in contentData) {
          messages.fi.userContent[key] = contentData[key].find((x: any) => x.language == "fi").text;
          messages.en.userContent[key] = contentData[key].find((x: any) => x.language == "en").text;
          messages.sv.userContent[key] = contentData[key].find((x: any) => x.language == "sv").text;
        }

        resolve(true);
      })
      .catch((e: any) => {
        //todo default values like this?
        Vue.prototype.$content = {
          header: {
            logotitle: [
              { language: "fi", text: "" },
              { language: "sv", text: "" },
              { language: "en", text: "" },
            ],
          },
          frontpage: {
            title: [
              { language: "fi", text: "Leimun asiointi" },
              { language: "sv", text: "" },
              { language: "en", text: "" },
            ],
            subtitle: [
              { language: "fi", text: "" },
              { language: "sv", text: "" },
              { language: "en", text: "" },
            ],
            paragraph: [
              { language: "fi", text: "" },
              { language: "sv", text: "" },
              { language: "en", text: "" },
            ],
          },
        };
        resolve(true);
      });
  });
}

function checkAuth() {
  return new Promise((resolve) => {
    api
      .GetSession()
      .then((response) => {
        store.state.session = response.data;
        resolve(true);
      })
      .catch((e) => {
        resolve(true);
      });
  });
}

function createApp() {
  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

initSettings().then(() => checkAuth().then(createApp));
