



























































































































import { Component, Vue } from "vue-property-decorator";
import { Session } from "@/models/Session";
import moment from "moment";

@Component
export default class OrgInfo extends Vue {
  private loading = true;
  private orgUsers = [];

  private allUsers: Record<string, any> = [];
  private fields: Record<string, any> = [];
  private filter = "";

  private totalRows = 0;
  private perPage = 20;
  private currentPage = 1;

  created() {
    this.fields = [
      { key: "name", label: "Nimi", sortable: true },
      { key: "email", label: "Sähköposti", sortable: true },
      {
        key: "lastLogin",
        label: "Viimeisin kirjautuminen",
        sortable: true,
        formatter: (value: any, key: any, item: any) => {
          return moment(value).format("D.M.YYYY");
        },
      },
      { key: "role", label: "", sortable: false },
    ];
    this.getOrgUsers();
  }

  getOrgUsers() {
    this.$api
      .GetOrgUsers()
      .then((response) => {
        this.orgUsers = response.data;
        this.initLists();
      })
      .catch((e) => {
        console.log("error getOrgUsers");
      });
  }

  accept(user: any) {
    this.$api
      .UpdateOrgUserRole(user.guid, 3) //todo enum
      .then((response) => {
        user.role = 3;
      })
      .catch((e) => {
        console.log("error accept");
      });
  }

  decline(user: any) {
    this.$api
      .UpdateOrgUserRole(user.guid, 99) //todo enum
      .then((response) => {
        user.role = 99;
      })
      .catch((e) => {
        console.log("error decline");
      });
  }  
  
  promote(user: any) {
    this.$api
      .UpdateOrgUserRole(user.guid, 2) //todo enum
      .then((response) => {
        user.role = 2;
      })
      .catch((e) => {
        console.log("error promote");
      });
  }   
  public initLists() {
    this.orgUsers.forEach((user: any) => {
      this.allUsers.push({
        guid: user.GUID,
        role: user.Role,
        name: user.GivenName + " " + user.LastName,
        email: user.Mail,
        lastLogin: user.LastLogin,
      });
    });
    this.totalRows = this.orgUsers.length;
    this.currentPage = 1;
    this.loading = false;
  }

  get pendingUsers() {
    return this.allUsers.filter((x: any) => x.role == 10);
  }

  get adminUsers() {
    return this.allUsers.filter((x: any) => x.role == 2);
  }

  get activeUsers() {
    return this.allUsers.filter((x: any) => x.role == 3);
  }

  get removedUsers() {
    return this.allUsers.filter((x: any) => x.role == 99);
  }

  public onFiltered(filteredItems: any) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
}
