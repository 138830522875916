<template>
  <div>
    <p>{{ text() }}</p>
  </div>
</template>

<script>
import extendComponent from '@/mixins/extendComponent'
export default {
  mixins: [extendComponent]
}
</script>
