




























import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { Message } from "@/models/Message";


@Component
export default class Messages extends Vue {

  public openMessage(message: Message) {
    this.$api
      .MarkMessageAsOpened(message)
      .then((response) => {
        this.$store.dispatch("checkMessages"); //todo turha haku?
      })
      .catch((error) => {
        console.error("Error: ", error);
      });

    if (message.Route) this.$router.push(message.Route);
  }  
}
